.header {
    border-bottom: .2rem solid #4ea5d9ff;
    background-color: #14080eff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    position: sticky;
    top: 0;
    max-width: 100vw;
}

.menu-item {
    padding: .5rem;
    margin: .5rem;
    background: transparent;
}