.App {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.bio {
  background-color: #eee;
  padding: 2rem;
}

.Profile_Pic {
  height: 40vmin;
  border-radius: 2rem;
  pointer-events: none;
}

.App-header {
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: #8acb88ff;
}

a:visited{
  color: #4ea5d9ff;
}

button:hover{
  color: #ffc857ff;
}

a:hover{
  color: #ffc857ff;
}

.footer a {
  padding: 0.2rem 1.3rem;
}

.grid-container a :hover {
  background-color: #ffc857ff;
  color: #8acb88ff;
}
