.grid-container {
display: grid;
max-width: 100vw;
grid-template-columns: repeat(2, 1fr);
grid-auto-rows: 28rem;
gap: 2rem;
}

.card {
    background: #eee;
    border-radius: 2rem;
    }

@media screen and (max-width: 650px) {
    .grid-container {
        display: grid;
        max-width: 100vw;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 28rem;
        gap: 2rem;
        }
        
        .card {
            background: #eee;
            border-radius: 2rem;
            }
}

